import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ToastyService } from '@app/_services/toasty.service';
import { IDENT_EXP_DATE_KEY, IDENT_KEY } from '@app/_shared/utils/storage-helper';
import {
  User,
  UserVerifyModel,
  ContactInfo,
  Password,
  Preferences,
  DeviceModel,
  ITwoFAAuthenticationInfo,
  VerificationOptionEnum
} from '@app/models';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private toastyService: ToastyService,
    private router: Router
  ) {
    this.baseUrl = `${environment.apiUrl}/account`;
  }

  getAccount(accountId: number): Observable<any> {
    const endpoint = `${this.baseUrl}/${accountId}`;
    return this.httpClient.get(endpoint).pipe(
      map((res) => User.fromJson(res)),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.message);
      })
    );
  }

  login(body: {
    username: string;
    password: string;
    remember: boolean;
    ipTracking?: boolean
  }): Observable<{ userAccount: User.Model; jwtToken: string; }> {
    const url = `${this.baseUrl}/token`;

    if (body.remember) {
      this.authService.setRemember();
    }

    delete body.remember;

    return this.httpClient.post<Record<string, any>>(url, body)
      .pipe(
        map((res) => ({
          userAccount: User.fromJson(res),
          jwtToken: res.token as string
        })),
        catchError((error: HttpErrorResponse) =>
          throwError(new Error(error.error.message))
        )
      );
  }

  logout(accountId: number, logoutFormAllDevices: boolean): Observable<{}> {
    const url = `${this.baseUrl}/token/logout`;

    const body = {
      account_id: accountId,
      logout_form_all_devices: logoutFormAllDevices
    };

    return this.httpClient.post<{}>(url, body);
  }

  checkDeviceVerificationCode(data: {
    jwt: string;
    accountId: number;
    verificationCode: string;
    tfaType: VerificationOptionEnum;
    secret?: string,
    isLogin?: boolean,
    deviceHash?: string
  }): Observable<{ isValid: boolean }> {
    const url = `${this.baseUrl}/token/verification-code/check`;
    let localIdent	= localStorage.getItem(IDENT_KEY);
    const identExpDate	= localStorage.getItem(IDENT_EXP_DATE_KEY) ?  new Date(+localStorage.getItem(IDENT_EXP_DATE_KEY)) : null;

    if (identExpDate && identExpDate.getTime() < Date.now()) {
      localIdent = undefined;
    }

    let headers: HttpHeaders;
    if (data.isLogin) {
      headers = new HttpHeaders({
        'Cookie-D-Id': data.deviceHash
      });
    }

    const body = {
      jwt: data.jwt,
      account_id: data.accountId,
      verification_code: data.verificationCode,
      ident: localIdent ? localIdent : undefined,
      tfa_type: data.tfaType,
      secret: data.secret
    };

    return this.httpClient.post<{ is_valid: boolean, ident?: string }>(url, body, { headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(error.error);
        }),
        map(({ is_valid, ident }) => {
          if (!localIdent) {
            const expDate = new Date();
            // Add 10 years to the current date
            expDate.setFullYear(expDate.getFullYear() + 10);
            localStorage.setItem(IDENT_KEY, ident);
            localStorage.setItem(IDENT_EXP_DATE_KEY, expDate.getTime().toString());
          }

          return { isValid: is_valid };
        })
      );
  }

  resendDeviceVerificationCode(data: {
    accountId: number;
    jwt: string;
  }): Observable<{}> {
    const url = `${this.baseUrl}/token/verification-code/resend`;

    const body = {
      jwt: data.jwt,
      account_id: data.accountId,
    };

    return this.httpClient.post<{}>(url, body)
      .pipe(
        catchError((httpError: HttpErrorResponse) => {
          return throwError(httpError.error);
        })
      );
  }

  updateDeviceStatusToVerified(accountId: number, isLogin?: boolean, jwtToken?: string): Observable<{}> {
    const url = `${this.baseUrl}/token/verify-device/${accountId}`;

    let headers: HttpHeaders;
    if (isLogin) {
      headers = new HttpHeaders({
        'Authorization': `Bearer ${jwtToken}`
      });
    }

    return this.httpClient.patch<{}>(url, { status }, { headers });
  }

  getDeviceList(accountId: number): Observable<DeviceModel[]> {
    const url = `${this.baseUrl}/token/devices-list/${accountId}`;

    return this.httpClient.get<any[]>(url).pipe(
      map((json) => json.map((item) => DeviceModel.fromJSON(item)))
    );
  }

  removeDeviceEntryById(deviceId: number): Observable<{}> {
    const url = `${this.baseUrl}/token/${deviceId}`;

    return this.httpClient.delete<{}>(url);
  }

  get2faInfo(accountId: number, type = VerificationOptionEnum.App, jwtToken?: string, isLogin?: boolean): Observable<ITwoFAAuthenticationInfo> {
    const url = `${this.baseUrl}/token/2fa/${accountId}`;

    let body;
    if (isLogin) {
      const deviceHash = this.authService.getDeviceHash();
      body = {
        type,
        'jwt': `${jwtToken}`,
        'device_hash': deviceHash
      };
    } else {
      body = {
        type
      };
    }

    return this.httpClient.patch<{base32: string; qr_image_url: string}>(url, body)
      .pipe(map(data => ({
      qrImageUrl: data.qr_image_url,
      id: data.base32
    })));
  }

  changeAuthDefaultTypeEmail(accountId: number): Observable<unknown> {
    const url = `${this.baseUrl}/token/2fa/${accountId}`;

    return this.httpClient.patch(url, {type: VerificationOptionEnum.Email});
  }

  check2faVerificationCode(account_id: number, verification_code: string, tfa_type: VerificationOptionEnum, secret?: string): Observable<boolean> {
    const url = `${this.baseUrl}/token/verification-code/2fa-type`;

    return this.httpClient.post<boolean>(url, {account_id, verification_code, tfa_type, secret});
  }

  updatePassword(obj: Password.Model) {
    const { oldPassword, newPassword } = obj;
    const accountId = this.authService.getAuthUserSnapshot().id;
    const endpoint = `${this.baseUrl}/${accountId}/password`;

    return this.httpClient
      .put<{ modified: 0 | 1 }>(endpoint, {
        old_password: oldPassword,
        new_password: newPassword,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(new Error(error.error.message));
        })
      );
  }

  updateAccountInfo(obj: ContactInfo.Model) {
    const accountId = this.authService.getAuthUserSnapshot().id;
    const body = ContactInfo.toJson(obj);
    const endpoint = `${this.baseUrl}/${accountId}`;

    return this.httpClient.put<{ modified: 0 | 1 }>(endpoint, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(new Error(error.error.message));
      })
    );
  }

  createAccount(body: { email: string; password: string; role: string }) {
    const endpoint = `${this.baseUrl}`;

    return this.httpClient.post(endpoint, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  checkEmailExisting(body: { email: string }) {
    const url = `${this.baseUrl}/email/_check`;

    return this.httpClient.post<{ exists: true }>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(new Error(error.error.message));
      })
    );
  }

  verifyEmail(data) {
    const url = `${this.baseUrl}/${data.id}/_verify-email`;

    return this.httpClient.post(url, data).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error.message === 'Already verified') {
          this.toastyService.info('Your email is verified!', null, { duration: 3000 });
          if (this.authService.getToken()) {
            this.router.navigate(['/my']);
          }
        }
        else if (error.error.message === 'EMAIL_ALREADY_EXISTS') {
          this.toastyService.error('Email already exists', null, { duration: 3000 });
          this.router.navigate(['/home']);
        }
        else if (error.error.message === 'NO_PENDING_EMAIL') {
          this.toastyService.error('The link is no longer valid', null, { duration: 3000 });
          this.router.navigate(['/home']);
        }
        else {
          this.toastyService.error('Wrong hash/id combination', null, { duration: 3000 });
          this.router.navigate(['/home']);
        }
        return throwError(error);
      })
    );
  }

  verifyUser(value: UserVerifyModel) {
    const url = `${this.baseUrl}/${value.id}/_verify`;

    return this.httpClient.post(url, value);
  }

  getAccountPreferences(accountId: number): Observable<User.Preferences[]> {
    const url = `${this.baseUrl}/${accountId}`;
    return this.httpClient.get(url).pipe(
      map((res: User.Model) => res.preferences),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  getAccountPermissions(accountId: number) {
    const url = `${this.baseUrl}/${accountId}/permissions`;
    return this.httpClient.get(url).pipe(
      tap(console.log),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  getAccountInfo(accountId: number): Observable<User.Model> {
    const url = `${this.baseUrl}/${accountId}`;
    return this.httpClient.get(url).pipe(
      map((res) => User.fromJson(res)),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  saveAllSettings(preferences: Preferences.Model): Observable<any> {
    const accountId = this.authService.getAuthUserSnapshot().id;
    const endpoint = `${this.baseUrl}/${accountId}/preferences`;
    preferences = Preferences.toJson(preferences);

    const prefsArray = [];
    if (preferences) {
      for (const i in preferences) {
        if (preferences[i]) {
          prefsArray.push(i.replace(/_/g, ' '));
        }
      }
    }

    const body = { preferences: prefsArray };

    return this.httpClient.put(endpoint, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error.message);
      })
    );
  }

  createContact(value: any): Observable<{}> {
    const url = this.baseUrl + '/contact';
    const body = {
      account_name: value.accountName || null,
      contact_name: value.contactName || null,
      email: value.email,
      phone_number: value.phoneNumber || null,
      reason: value.reason || null,
      comment: value.comment,
    };

    return this.httpClient.post<{}>(url, body).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.toastyService.error('Something went wrong!', 'Close');
        return throwError(errorResponse.error);
      })
    );
  }

  resetPassword(email: { email: string }) {
    const url = this.baseUrl + '/password/_reset';
    return this.httpClient.post(url, email).pipe(
      tap(() => {
        this.toastyService.success(
          'We sent an email to your email address with instructions to reset your password.',
          'Close'
        );
      }),
      catchError((error) => {
        this.toastyService.error(error.error.message, 'Close');
        return throwError(error);
      })
    );
  }

  updateUserAgreement(
    value: User.Agreement,
    accountId: number
  ): Observable<{ [key: string]: any, token: string }> {
    const url = `${this.baseUrl}/${accountId}`;

    return this.httpClient.put<{ [key: string]: any, token: string }>(url, User.agreementToJson(value));
  }
}
